import styles from 'pages/Patient/HowItWorks/HowItWorks.module.scss';
import { catalogUrls, providersUrls } from 'app/routing';
import { Page } from 'app/types/ProductCatalog';
import Button from 'components/Button/Button';
import { Body, Caption, Heading } from 'components/Typography';
import { ReactComponent as Image1 } from 'pages/Patient/HowItWorks/assets/img1.svg';
import { ReactComponent as Image2 } from 'pages/Patient/HowItWorks/assets/img2.svg';
import { ReactComponent as Image3 } from 'pages/Patient/HowItWorks/assets/img3.svg';
import { ReactComponent as Image4 } from 'pages/Patient/HowItWorks/assets/img4.svg';
import { ReactComponent as Image5 } from 'pages/Patient/HowItWorks/assets/img5.svg';
import HowItWorksItem from 'pages/Patient/HowItWorks/components/HowItWorksItem/HowItWorksItem';

import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';

export const HowItWorks: React.FC<Page> = ({ setPageHeader }) => {
  useEffect(() => setPageHeader(undefined), [ setPageHeader ]);

  return (
    <>
      <Helmet title="How it works" />
      <div className={styles.howItWorks}>

        <Heading variant="H3" className={styles.howItWorks__title}>What happens to your sample?</Heading>

        <div className={styles.howItWorks__items}>

          <HowItWorksItem image={<Image1 />}>
            <Body variant="S">After your Physician orders a Galatea Bio test/report, you will either:</Body>

            <Body variant="S" style={{ marginTop: 14 }}>* Have your buccal sample taken at your physician’s office</Body>

            <div className={styles.howItWorks__separator}>
              <Caption className={styles.howItWorks__or} variant="L">OR</Caption>
            </div>

            <Body variant="S">* You will be sent a buccal swab collection kit to self administer the sample collection.</Body>
          </HowItWorksItem>

          <HowItWorksItem image={<Image2 />}>
            <Body variant="S">Once your sample arrives at Galatea Bio’s laboratory, all personal identification is removed and it gets a code number.</Body>
          </HowItWorksItem>

          <HowItWorksItem image={<Image3 />}>
            <Body variant="S">Your DNA is extracted, stored, preserved and sequenced at our HQ facilities in Miami, Florida.</Body>
          </HowItWorksItem>

          <HowItWorksItem image={<Image4 />}>
            <Body variant="S">
              We have state of the art Illumina DNA sequencers, which can sequence billions of nucleotides
              with extraordinary throughput and accuracy within a few days.
            </Body>
          </HowItWorksItem>

          <HowItWorksItem image={<Image5 />}>
            <Body variant="S">
              Galatea Bio analyzes your genetic data and produces a comprehensive report.<br />
              Whether you are ordering a clinical genetic test or a PRS Report, your physician will be your guide in understanding your results.<br />
              In the case of clinical genetic testing, you may opt-in for additional support from a genetic counselor.
            </Body>
          </HowItWorksItem>

        </div>

        <div className={styles.howItWorks__conclusion}>
          <Body variant="L">
            If your physician is not yet enrolled to order Galatea Bio Clinical Genetic Tests and ancestry-aware-PRS Reports,&nbsp;
            <span className={styles.howItWorks__enroll}>they can enroll by simply filling out the enrollment form&nbsp;
              <Link to={providersUrls.howItWorks}>here</Link>:
            </span>
          </Body>

          <div className={styles.howItWorks__explore}>
            <Body variant="L">Explore our catalog <br /> with your physician&apos;s guidance</Body>

            <Link to={catalogUrls.products}><Button size="L">Testing Catalog</Button></Link>
          </div>
        </div>

      </div>
    </>
  );
};

export default HowItWorks;
